.sideNavWrapper {
    position: absolute;
    padding-top: $topbar-height;
    top: 0;
    left: 0;
    width:var(--navbar-width);
    max-width: var(--navbar-width);
    height: 100vh;
    background-color: $navbar-background-color;
    z-index: $navbar-z-index;
}

.sideNavList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.navLink {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: var(--navbar-width);
    width: var(--navbar-width);
    &.active {
        background-color: $navbar-active-icon-background-color !important;
        color: $navbar-active-icon-color !important;
    }
    &:hover {
        background-color: $navbar-icon-hover-background-color !important;
        color: $navbar-active-icon-color !important;
    }
    i.navIcon:before {
        font-size: 21px;
    }
}

.navLinkGroupWrapper {
    display: flex;
    flex-direction: column;
}