.landingPageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .adminLink {
        width: fit-content;
        align-self: flex-end;
    }
}

.particlesWrapper {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
}

.landingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    .myDashboardsText {
        @include until($tablet) {
            display: none;
        }
    }
}

.landingPageBanner {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
    justify-content: center;
    align-items: center;
    @include until($tablet) {
        flex-basis: 15%;
        width: 100%;
        box-shadow: 0px 2px 12px 0px rgba(63, 63, 63, 0.219), inset 0px -2px 20px -7px rgba(0,0,0,0.219);
    }
}
.landingPageBannerText {
    font-weight: bold;
}

.landingPageContent {
    display: flex;
    flex-direction: column;
    flex-basis: 66%;
    justify-content: center;
    align-items: center;
    @include until($tablet) {
        flex-basis: 85%;
        justify-content: flex-start;
    }
}

.landingPageLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-height: 65vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
        display: none;
      }
    @include until($tablet) {
        max-height: 80vh;
    }
}

.dashboardLinkWrapper {
    margin: $landing-page-link-breathing-room;
    border: $landing-page-link-border;
    border-radius: $landing-page-link-border-radius;
    background-color: white;
    flex-wrap: wrap;
    cursor: pointer;
    &:hover {
        -webkit-box-shadow: $widget-box-shadow;
        -moz-box-shadow: $widget-box-shadow;
        box-shadow: $widget-box-shadow;
    }
}

.dashboardLink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin: $landing-page-link-breathing-room;
    color: $widget-title-color !important;
    &:focus {
        border: none !important;
        outline: none;
    }
}

.dashboardLinkHeaderText {
    display: flex;
    justify-content: center;
    font-weight: bold !important;
    align-items: center;
    margin: 10px;
}

.dashboardLinkDescriptionText {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}