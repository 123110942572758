.widgetsWrapper {
    padding: var(--content-breathing-room);
}

// widget layout
.widgetWrapper {
    z-index: 1;
    background-color: $widget-background-color;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: var(--content-breathing-room);


    border: $widget-border;
    border-radius: $widget-border-radius;
    &.editing {
        &:hover {
            border-bottom: 0px;
            border-right: 0px;
            box-shadow: $widget-box-shadow;
            -moz-box-shadow: $widget-box-shadow;
            box-shadow: $widget-box-shadow;
        }
    }
}

.widgetHeaderWrapper {
    .icon {
        margin-right: 1vw;
    }
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: flex-start;
    width: 100%;
    padding: 0px 0px .5em 0px;
    justify-content: space-between;
    .widgetHeaderRow2 {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-top: 1%;
        padding-left: 5px;
    }
}

.widgetNameWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1em;
}

.widgetButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.widgetHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.widgetName {
    display: flex;
    font-weight: bold;
    font-size: $widget-title-font-size;
    color: $widget-title-color;
}

.widgetContentWrapper {
    display: flex;
    padding: 3px 0px 0px;
    justify-content: center;
    overflow-y:auto;
    height: 100%;
    width: 100%;
    min-width: 0;
}

.widget {
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
}

.layoutElement {
    &:hover {
        z-index: 4;
    }
    &.expanded {
        transform: none !important;
        width: 100% !important;
        height: calc(100vh - 2 * #{$topbar-height}) !important;
        position: sticky !important;
        top: 20px !important;
        z-index: 4;
    }
}

// group by container
.groupByWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.groupByText {
    display: flex;
    font-size: $group-by-font-size;
    justify-self: flex-start;
    white-space: nowrap;
    font-weight: bold;
    padding-left: .8vw;
    padding-right: .5vw;
}

.groupBySelectWrapper {
    display: flex;
    justify-self: flex-end;
    flex-basis: 100%;
    min-width: 100px;
}

// number
.numberWidgetWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.numberWidgetText {
    display: flex;
    font-weight: bold;
    font-size: 2.5rem;
}

// bar chart 
.barChartYAxisLabel {
    transform-origin: 10px 30px;
    font-weight: bold;
}

.barChartXAxisLabel {
    font-weight: bold;
}

// recharts overrides
.recharts-legend-item { 
    cursor: pointer;
        :active {
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
}

.recharts-surface {
    overflow: visible;
}

.legendButtons {
    width: 100%;
    display: flex;
    flex-direction: row;

}

.legendButton {
    @extend a;
    padding-left: 3%;
    width: fit-content;
    word-spacing: -.8px;
    letter-spacing: -.3px;
}

// top list
.gridWrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;

    // firefox scrollbar configuration
    scrollbar-width: thin;
    scrollbar-color: rgba(99, 99, 99, 0.911) #F5F5F5;

    // chrome + edge scrollbar configuration
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track
    {
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.116);
        border-radius: 20px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar {
        display: contents;
        width: 4px;
        height: 10px;
        background-color: #F5F5F5;
        }
    &::-webkit-scrollbar-thumb
    {
        border-radius: 20px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(99, 99, 99, 0.911);
    }
}

tr {
    &.active {
        background-color: $link !important;
        color: white !important;
        a {
            color: white !important;
            text-decoration: underline;
        }
    }
    &.pointer {
        cursor: pointer;
    }
}

// .gridColumnHeader {
//     display: flex;
//     justify-content: space-around;
// }



// map

// // container
.mapContainer {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
}

.mapContentWrapper {
    display: flex;
}

.mapFlexWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.mapWrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.map {
    height: 100%;
    width: 100%;
}

// // zoom / extent / attribution styling
.zoomToExtentWrapper, .zoomWrapper {
    background-color: white;
    color: $map-theme-color;
}

.zoomWrapper {
    display: flex;
    padding: 0px;
    margin: 0px;
    button {
        margin: 0px;
    }
    .zoomWrapper-in {
        height: $zoom-icon-height;
        width: $zoom-icon-width;
    }
    .zoomWrapper-out {
        height: $zoom-icon-height;
        width: $zoom-icon-width;
        left: calc(.5em  + #{$zoom-icon-width});
        top: .5em;
    }
}

.zoomToExtentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    top: .5em;
    left: calc(.5em + (0 * #{$zoom-icon-width}));
    height: $zoom-icon-height;
    width: $zoom-icon-width;
    button, .zoomToExtentDiv {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &:focus {
        outline: none;
    }
}

.attributionWrapper {
    font-size: x-small;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

// // openlayers controls overrides
.ol-control {
    background-color: white;
    color: $map-theme-color;
    &:hover {
        background-color: white;
    }
    
    button { 
        color: $map-theme-color;
        background-color: white;
        border-color: $map-theme-color;
        &:hover, &:focus {
            outline: none;
            background-color: white;
            border-color: $map-theme-color;
        }
    }    
}



// // icon borders
.zoomToExtentWrapper {
    border: solid 1px $map-theme-color;
    // border-left: none;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.24), 0px 2px 2px 0px rgba(0,0,0,0.24);
    border-radius: 2px;

}
.zoomWrapper-out {
    border: solid 1px $map-theme-color !important;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.24), 0px 2px 2px 0px rgba(0,0,0,0.24);

}

.zoomWrapper-in {
    border: solid 1px $map-theme-color !important;
    border-right: none !important;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.24), 0px 2px 2px 0px rgba(0,0,0,0.24);

}

// // tooltip

.mapTooltipWrapper {
    position: absolute;
    top: 10px;
    z-index: 2;
    display: none;
    max-width: 450px;
    background-color: transparent;
    margin: 0px 15px;
}

.mapTooltipContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-color: $map-tooltip-background-color;
    border: $map-tooltip-border;
    padding-top: calc(#{$map-tooltip-breathing-room} / 2);
    padding-bottom: calc(#{$map-tooltip-breathing-room} / 2);
    padding-left: $map-tooltip-breathing-room;
    padding-right: $map-tooltip-breathing-room;
    border-radius: 5px;
}

.mapTooltipRowWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-basis: 100%;
}

.mapTooltipLabel {
    font-weight: $map-tooltip-label-font-weight;
    font-size: $map-tooltip-font-size;
    padding-right: 0.5em;
}

.mapTooltipValue {
    font-weight: $map-tooltip-value-font-weight;
    font-size: $map-tooltip-font-size;
}

.recharts-tooltip-item {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    .recharts-tooltip-item-separator {
        display: none;
    }
    .recharts-tooltip-item-unit {
        display: none;
    }
    .recharts-tooltip-item-name {
        padding-right: 15px;
    }
}

.recharts-default-tooltip {
    background-color: white;
    padding: 10px;
    border: 1px solid rgb(204, 204, 204);
}

// no data component 
.noDataComponentWrapper {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.noDataComponentText {
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .numberWidgetText;
}


.recharts-legend-item-text {
    @extend .is-size-7
}

#menu-close {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.mapLegendWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 4px;
}

.mapLegendItemWrapper {
    display: flex;
    justify-content: center;
    font-size: 0.8em;
    margin: 2px 10px;
}

.mapLegendItemColor {
    display: flex;
    align-items: center;
    margin-right: 3px;
}
.mapLegendItemSwatch {
    display: flex;
    padding: 5px;
}

// form
.formWrapper {
    display: flex;
    width: 100%;
    height: calc(100% - 3 * var(--content-breathing-room));
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
        display: none;
        }
    &:hover{
        // firefox scrollbar configuration
        scrollbar-width: thin;
        scrollbar-color: rgba(99, 99, 99, 0.911) #F5F5F5;

        // chrome + edge scrollbar configuration
        &::-webkit-scrollbar-track
        {
            box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.116);
            border-radius: 20px;
            background-color: #F5F5F5;
        }
        &::-webkit-scrollbar {
            display: contents;
            width: 4px;
            height: 10px;
            background-color: #F5F5F5;
            }
        &::-webkit-scrollbar-thumb
        {
            border-radius: 20px;
            box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: rgba(99, 99, 99, 0.911);
        }}

}

.formValue {
    margin-left: 10px;
}
.formFieldWrapper {
    padding: unset !important;
}
.formField {
    margin: 5px 20px !important;
    white-space: nowrap;
}

// grid
.tableCell {
    white-space: nowrap;
}

.table {
    thead {
        position: sticky;
        top: 0;
        z-index: 2;
        background: white;
      }
    tbody {
        overflow: visible !important;
        height: 200px;
      }
    .sticky {
        position: sticky !important;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: white;
    }
}

// 3d feature map
.compass {
    position: absolute;
    z-index: 4;
    height: 20%;
}

.hidden {
    display: none !important;
}

.recharts-legend-wrapper {
    max-height: 20%;
    -ms-overflow-style: none;
    // firefox scrollbar configuration
    scrollbar-width: thin;
    scrollbar-color: rgba(99, 99, 99, 0.911) #F5F5F5;

    // chrome + edge scrollbar configuration
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track
    {
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.116);
        border-radius: 20px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar {
        display: contents;
        width: 4px;
        height: 10px; 
        background-color: #F5F5F5;
        }
    &::-webkit-scrollbar-thumb
    {
        border-radius: 20px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(99, 99, 99, 0.911);
    }
}

.overflow-disabled {
    overflow-y: unset;
}